import { Component, Vue } from "vue-property-decorator";

import { Header,Footer } from "@/layout";
import { APLightbox, APConfirmDelete } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { RollcallModel } from "@/models";
import { TeCommon } from "../Common";

export class SearchForm {
  time = '';
  clid = '';
  sid = '';
  cid = '';
}

type response = { [key: string]: unknown };

@Component<ApTeacherRollcallIndexController>({
    components: {
        Header,APLightbox, APConfirmDelete,Footer
    }
})
export default class ApTeacherRollcallIndexController extends Vue {
    private doubleClick = false; 
    private searchForm = new SearchForm();
    private cityList: object = {};
    private schoolList: object = {};
    private classList: object = {};
    private rollcallList: object = {};
    private num = 0;
    private page = 1;
    private perpage = 50;
    private maxPage = 0;
    private rid = 0;
    private tid = window.localStorage.getItem('teacher_tid') as string;
    private token = window.localStorage.getItem('teacherToken') as string;

    private lightbox: { showLB: boolean, errorMsg: string } = {
        showLB: false, 
        errorMsg:  '',
    };

    public async created() {
        this.getRollcallList();
        this.cityList = await TeCommon.getTECity(this.tid);//取得該老師所屬班級的所有縣市
    }

    //取得該老師所屬班級之該縣市分校列表
    public async getTESchool(cid: string) {
        this.searchForm.sid = '';
        this.searchForm.clid = '';
        this.schoolList = await TeCommon.getTESchool(cid, this.tid);
        this.classList = [];
    }

    //取得該老師所屬班級之該分校班級列表
    public async getTEClass(sid: string) {
        this.searchForm.clid = '';
        this.classList = await TeCommon.getTEClass(sid, this.tid);
    }

    public async getRollcallList(){
        const item = {
            page: this.page,
            perpage: this.perpage,
            time : this.searchForm.time,
            clid : this.searchForm.clid,
            sid : this.searchForm.sid,
            cid : this.searchForm.cid,
            tid : this.tid,
            token: this.token,
        }
        const data = await RollcallModel.teGetList(item) as response;
        TeCommon.checkToken(data.ERR_CODE as number);
        this.num = data.num as number;
        this.rollcallList = data.list as object;
        this.maxPage = Math.ceil(this.num/this.perpage);
    }

    private getPageList(page: number) {
        this.page = (page < 1) ? 1 : (page > this.maxPage) ?  this.maxPage : page;
        this.getRollcallList();
    }

    public delRollcall(id: number){
        this.$emit("openConfirmDelete", true);
        this.rid = id;
    }

    public async deleteRollcall(){
        if(!this.doubleClick){
            this.doubleClick = true; 

            const item = {
                rid: this.rid,
                token: this.token,
            }
            const errNo = await RollcallModel.teDel(item);
            this.$emit("closeConfirmDelete", false);
            switch (errNo) {
                case ErrorCode.InvalidToken:
                    TeCommon.logout();
                    break;
                case ErrorCode.Success:
                    this.doubleClick = false;
                    this.$router.go(0); //reload page
                    break;
                default:
                    this.lightbox.errorMsg = ErrorMessage[errNo];
                    this.lightbox.showLB = true;
                    this.doubleClick = false;
                    break;
            }
        }
    }

    private closeLB() {
        this.lightbox.showLB = false;
    }
}